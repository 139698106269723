
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
    Button,
    Input,
    FormGroup,
    Label,
} from "reactstrap";

import classNames from "classnames";
import styled from "styled-components";
import BundleForm from "./CreateEditBundle";
import {
    saveCreditBundles,
    searchCreditBundles,
    deleteCreditBundles,
    getCreditRate,
} from "../../redux/reducers/creditBundlesReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import * as Icon from "react-feather";

const Overlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.18);
  z-index: 1;
`;

const CreditBundlesManager = () => {
    const dispatch = useDispatch();

    const [formShown, setFormShown] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [reload, setReload] = useState(true);
    const [inclDel, setInclDel] = useState(false);
    const [bundle, setBundle] = useState();

    const [data, setData] = useState([]);
    const [rate, setRate] = useState();


    const handleIncludeDeletedChange = (e) => {
        setInclDel(e.target.checked);
        setReload(true);
    }

    const collectParams = () => {
        return { inclDel };
    }

    const fetchData = useCallback((params) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    searchCreditBundles,
                    {
                        params,
                        successCallback: (response = {}) => {
                            setData(response);
                            setFetching(false);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { setFetching(false); }
        })();
    }, []);

    const fetchCreditRate = useCallback(() => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    getCreditRate,
                    {                        
                        successCallback: (response = {}) => {
                            setRate(response);
                            setFetching(false);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { setFetching(false); }
        })();
    }, []);

    useEffect(() => {
        if (reload) {
            const params = collectParams();
            fetchData(params);
            fetchCreditRate();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        const params = collectParams();
        fetchData(params);
        fetchCreditRate();
        setReload(false);
    }, []);

    const handleShowHideForm = (flag) => { // true: show the popup form
        setFormShown(flag);
    }


    const handleSaveBundle = (bundleState) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    saveCreditBundles,
                    {
                        params: bundleState,
                        successCallback: (response = {}) => {
                            setFetching(false);
                            setFormShown(false);
                            setReload(true);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { setFetching(false); }
        })();
    }

    const createBundle = () => {
        setBundle(null);
        setFormShown(true);
    }

    const editBundle = (_bundle) => {
        setBundle(_bundle);
        setFormShown(true);
    }

    const deleteBundle = (id) => {
        (async () => {
            const params = { id };
            try {
                setFetching(true);
                await startActionWithPromise(
                    deleteCreditBundles,
                    {
                        params,
                        successCallback: (response = {}) => {
                            setFetching(false);
                            setFormShown(false);
                            setReload(true);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { setFetching(false); }
        })();
    }


    return (
        <Container fluid className="p-0 h-100 d-flex flex-column">
            <Row>
                <Col lg="4"><h1 className="page-title">Credit bundles</h1></Col>
                <Col lg="2">
                        <Label check for="creditRate">
                            Credit rate: <b>${rate}</b>
                        </Label>
                </Col>
                <Col lg="4" >
                    <div class="d-flex align-items-center justify-content-end flex-wrap w-100 mr-4">
                        <FormGroup check className="ml-2">
                            <Input type="checkbox"
                                id="inclDel"
                                name="inclDel"
                                value={inclDel}
                                onChange={handleIncludeDeletedChange}
                            />
                            {' '}
                            <Label check for="inclDel">
                                Including deleted bundles
                            </Label>
                        </FormGroup>
                        <Button
                            className="ml-4"
                            color="primary"
                            disabled={fetching}
                            onClick={createBundle}
                        >
                            Add new
                        </Button>
                    </div>
                </Col>
                <Col lg="2">
                </Col>
            </Row>

            <Row className="h-100 overflow-auto">
                <Col lg="12" className="h-100 d-flex">
                    <Card className={classNames("w-100 mb-0 datatable-wrap")}>
                        <CardBody className="py-0 data-list initial-scrollbar position-relative">
                            <Row className="h-100">
                                {fetching && (
                                    <Overlay>
                                        <Spinner />
                                    </Overlay>
                                )}
                                <Col className="h-100" lg="12" md="12">
                                    <Row className="font-weight-bold mb-2">
                                        <Col lg="2" md="2" className="border-bottom  mr-2">Name</Col>
                                        <Col lg="1" md="1" className="border-bottom mr-2">Price (USD)</Col>
                                        <Col lg="1" md="1" className="border-bottom mr-2">Credits</Col>
                                        <Col className="border-bottom mr-2">Description</Col>
                                        <Col lg="2" md="2" className="border-bottom d-flex justify-content-center">
                                        </Col>
                                    </Row>
                                    {data.map((row, index) => (
                                        <Row key={row.id} style={{ backgroundColor: (index % 2) == 0 ? "" : '#f8f9fa', textDecoration: row.deleted ? 'line-through' : '' }} className="pt-1">
                                            <Col lg="2" md="2" className="mr-2" >{row.name}</Col>
                                            <Col lg="1" md="1" className="mr-2">{row.price}</Col>
                                            <Col lg="1" md="1" className="mr-2">{row.credits}</Col>
                                            <Col className="mr-2">{row.description}</Col>
                                            <Col lg="2" md="2" className="d-flex">
                                                {!row.deleted && (
                                                    <>
                                                        <div title="Delete">
                                                            <Icon.Trash
                                                                onClick={() => deleteBundle(row.id)}
                                                                cursor={'pointer'}
                                                            />
                                                        </div>
                                                        <div className="ml-2" title="Modify">
                                                            <Icon.Edit
                                                                onClick={() => editBundle(row)}
                                                                cursor={'pointer'}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                            </Col>
                                        </Row>
                                    )
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {formShown && (
                <BundleForm
                    modal={formShown}
                    bundle={bundle}
                    onShowHideForm={handleShowHideForm}
                    onSubmitForm={handleSaveBundle}
                />
            )}
        </Container>
    );
}

export default CreditBundlesManager;