import React, { useState } from "react";
import {
    Row,
    Col,
    Input,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
} from "reactstrap";

const BundleForm = (props) => {
    const { loading, bundle } = props;
    const isNew = !bundle;

    const [state, setState] = useState({
        id: bundle?.id,
        uid: bundle?.uid,
        name: bundle?.name,
        price: bundle?.price,
        credits: bundle?.credits,
        description: bundle?.description,
        deleted: bundle?.deleted || false,
    });

    const handleSubmitForm = () => {
        props.onSubmitForm({...state});
    }

    const handleInputChange = (fieldName, fieldValue) => {
        setState({ ...state, [fieldName]: fieldValue });
    }

    return (
        <Modal
            isOpen={props.modal}
            centered
            size="lg"
            backdrop="static"
            keyboard={false}
        >
            <ModalHeader>
                <span className="">{isNew ? "Create new credit bundle" : "Edit credit bundle"}</span>
            </ModalHeader>
            <ModalBody className="pt-0 pb-0">
                <Card className="w-100 mb-0">
                    <CardBody className="pt-0 pb-0 data-list position-relative">
                        <Row className="row-cols-lg-auto g-3 align-items-center mt-2">
                            <Col md="6">
                                <FormGroup>
                                    <Label
                                        className="visually-hidden"
                                        for="name"
                                    >
                                        Name
                                    </Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={state.name}
                                        onChange={(e) =>
                                            handleInputChange("name", `${e.target.value}`)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="price">
                                        Price (USD)
                                    </Label>
                                    <Input
                                        type="number"
                                        id="price"
                                        name="price"
                                        value={state.price}
                                        onChange={(e) =>
                                            handleInputChange("price", `${e.target.value}`)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="credits">
                                        Credits
                                    </Label>
                                    <Input
                                        type="number"
                                        id="credits"
                                        name="credits"
                                        value={state.credits}
                                        onChange={(e) =>
                                            handleInputChange("credits", `${e.target.value}`)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="row-cols-lg-auto g-3 align-items-center">
                            <Col md="12">
                                <FormGroup>
                                    <Label for="description">
                                        Credits
                                    </Label>
                                    <Input
                                        type="textarea"
                                        id="description"
                                        name="description"
                                        value={state.description}
                                        rows={4}
                                        onChange={(e) =>
                                            handleInputChange("description", `${e.target.value}`)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    disabled={loading}
                    onClick={() => props.onShowHideForm(false)}
                >
                    Close
                </Button>
                <Button
                    className="ml-2"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitForm}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default BundleForm;