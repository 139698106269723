import { post, get, requestDelete } from "../helpers/apiHelper";

async function search(params) {
    return await get("credits/bundles", params);    
}

async function save(params) {
    return await post("credits/bundles", params);
}

async function markDelete(params) {
    const { id } = params;    
    return await requestDelete(`credits/bundles/${id}`)
}

async function creditRate() {
    return await get(`credits/rate`)
}

export default {
    search,
    save,
    markDelete,
    creditRate
};