import {
  PieChart as PieChartIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  AlertCircle as AlertIcon,
  Pocket as PocketIcon,
  PlayCircle as PlayCircleIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Clock as ClockIcon,
  CreditCard as CreditCard
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Dashboard from "../pages/dashboards";
import Users from "../pages/users";
import UsersTrusted from "../pages/users-trusted";
import BoUsers from "../pages/bousers";
import Permissions from "../pages/domains/permissions";
import Roles from "../pages/domains/roles";
import RoleGroups from "../pages/domains/roleGroups";
import RolesPermissions from "../pages/domains/RolesPermissions";
import ReviewVideos from "../pages/videos-review";
import UserProfile from "../pages/profile";
import Setting from "../pages/setting";
import AdminTools from "../pages/tools";
import CognitiveVideos from "../pages/videos";
import FeaturedVideos from "../pages/videos-featured";
import ErrorLogs from "../pages/logs";
import AuditLogs from "../pages/audit-logs";
import Orders from "../pages/orders";
import UsersStorage from "../pages/users-storage";
import VideosStatistics from "../pages/videos-statistics";
import UserSubscriptions from "../pages/user-subscriptions";
import MonthlyRevenues from "../pages/monthly-revenues";
import RevenueRecords from "../pages/revenue-records";
import FundsTransfers from "../pages/funds-transfers";
import ConnectedAccounts from "../pages/connected-accounts";
import Catalog from "../pages/catalog-versions";
import PlanOptionType from "../pages/plan-option-types";
import ProductPlan from "../pages/product-plans";
import ServiceProduct from "../pages/service-products";
import StoragePackage from "../pages/storage-packages";
import Products from "../pages/products";
import VideoSubscriptions from "../pages/video-subscription";
import MailMessaging from "../pages/mailboxes";
import ContactUsMessages from "../pages/contact-messages";


import CreditBundlesManager from "../pages/credits";


// Routes
const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  header: "MM BackOffice",
  badgeColor: "primary",
  // badgeText: "5",
  icon: PieChartIcon,
  containsHome: true,
  component: Dashboard,
};

const contentRoutes = {
  path: "/content",
  name: "Content",
  badgeColor: "primary",
  icon: PlayCircleIcon,
  children: [
    {
      path: "/content/review-videos",
      name: "Publishing requests",
      component: ReviewVideos,
    },
    {
      path: "/content/videos",
      name: "Cognitive videos",
      component: CognitiveVideos,
    },
    {
      path: "/content/videos-featured",
      name: "Featured videos",
      component: FeaturedVideos,
    },
    {
      path: "/content/videos-statistics",
      name: "Video statistics",
      component: VideosStatistics,
    },
    {
      path: "/content/resources",
      name: "Resources",
      component: Products,      
    },
    {
      path: "/content/course-subscriptions",
      name: "Course subscriptions",
      component: VideoSubscriptions,      
    },
  ],
};

const domainPermissionsRoutes = {
  path: "/domains",
  name: "Domains",
  badgeColor: "primary",
  icon: PocketIcon,
  requireBoAdmin: true,
  children: [
    {
      path: "/domains/permissions",
      name: "Permissions",
      component: Permissions,
    },
    {
      path: "/domains/roles",
      name: "Roles",
      component: Roles,
    },
    {
      path: "/domains/role-groups",
      name: "Role groups",
      component: RoleGroups,
    },
    {
      path: "/domains/roles-permissions",
      name: "Roles - Permissions",
      component: RolesPermissions,
    },
  ],
};

const userRoutes = {
  path: "/users",
  name: "MM Users",
  icon: UsersIcon,
  badgeColor: "info",
  badgeText: "",
  children: [
    {
      path: "/users",
      name: "Users",
      component: Users,
    },
    {
      path: "/users/contact-messages",
      name: "Contact messages",
      component: ContactUsMessages,
    },
    {
      path: "/users/sending-emails",
      name: "Users emails",
      component: MailMessaging,
    },
    {
      path: "/users/trusted-publishers",
      name: "Trusted publishers",
      component: UsersTrusted,
    },
    {
      path: "/users/storage",
      name: "Users storage",
      component: UsersStorage,
    },
  ],
};

const bouserRoutes = {
  path: "/bousers",
  name: "BO Users",
  icon: UsersIcon,
  badgeColor: "info",
  badgeText: "",
  component: BoUsers,
  requireBoAdmin: true,
};

const settingRoutes = {
  path: "/setting",
  name: "Settings",
  icon: SettingsIcon,
  badgeColor: "secondary",
  badgeText: "",
  requireBoAdmin: true,
  children: [
    {
      path: "/setting/:service",
      name: "Setting",
      component: Setting,
    },
  ],
};

// const adminToolRoutes = {
//   path: "/tools",
//   name: "Tools",
//   icon: SlidersIcon,
//   badgeColor: "secondary",
//   badgeText: "",
//   requireBoAdmin: true,
//   component: AdminTools
// };

export const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    // {
    //   path: "/auth/sign-up",
    //   name: "Sign Up",
    //   component: SignUp
    // },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};

const ErrorLogRoutes = {
  path: "/logs",
  name: "Logs",
  icon: AlertIcon,
  requireBoAdmin: true,
  children: [
    {
      path: "/logs/app-logs",
      name: "Platform applications logs",
      component: ErrorLogs,
    },
    {
      path: "/logs/audit-logs",
      name: "Audit logs",
      component: AuditLogs,
    },
  ],
}

// profile
const privateRoutes = {
  path: "/profile",
  name: "Profile",
  component: UserProfile,
};
/*
const ordersRoutes = {
  path: "/orders",
  name: "Orders",
  icon: ShoppingBagIcon,
  component: Orders,
  requireBoAdmin: true,
};
*/

const financeRoutes = {
  path: "/finance",
  name: "Finance",
  icon: CreditCard,
  badgeColor: "info",
  badgeText: "",
  requireBoAdmin: true,
  children: [
    {
      path: "/finance/orders",
      name: "Orders",
      component: Orders,
    },
    {
      path: "/finance/user-subscriptions",
      name: "User Subscriptions",
      component: UserSubscriptions,
    },{
      path: "/finance/revenue-records",
      name: "Revenue Records",
      component: RevenueRecords,
    },{
      path: "/finance/monthly-revenues",
      name: "Monthly Revenues",
      component: MonthlyRevenues,
    },{
      path: "/finance/funds-transfers",
      name: "Funds Transfers",
      component: FundsTransfers,
    },{
      path: "/finance/connected-accounts",
      name: "Connected Accounts",
      component: ConnectedAccounts,
    },{
      path: "/finance/catalog",
      name: "Catalog",
      component: Catalog,
    },{
      path: "/finance/credit-bundles",
      name: "Credit bundles",
      component: CreditBundlesManager,
    },    
  ],
};

const subscriptionRoutes = {
  path: "/subscription",
  name: "Subscription",
  icon: ShoppingBagIcon,
  badgeColor: "info",
  badgeText: "",
  requireBoAdmin: true,
  children: [
    {
      path: "/subscription/service-products",
      name: "Service Products",
      component: ServiceProduct,
    },{
      path: "/subscription/plan-option-types",
      name: "Plan Option Types",
      component: PlanOptionType,
    },{
      path: "/subscription/product-plans",
      name: "Product Plans",
      component: ProductPlan,
    },{
      path: "/subscription/storage-packages",
      name: "Storage Packages",
      component: StoragePackage,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  contentRoutes,
  //ordersRoutes,
  userRoutes,
  financeRoutes,
  subscriptionRoutes,
  bouserRoutes,
  domainPermissionsRoutes,
  // roleAndPermissionRoutes,
  settingRoutes,
  ErrorLogRoutes,
  // adminToolRoutes,
  privateRoutes,
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  contentRoutes,
  //ordersRoutes,
  userRoutes,
  financeRoutes,
  subscriptionRoutes,
  bouserRoutes,
  domainPermissionsRoutes,
  // adminToolRoutes,
  ErrorLogRoutes,
  // roleAndPermissionRoutes,
  // settingRoutes,
];
