import { combineReducers } from "redux";
import { waiterReducer } from "redux-waiters";

/** Theme reducer */
import { reducer as toastr } from "react-redux-toastr";
import { reducer as formReducer } from "redux-form";
import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";

/** End theme reducer */
import user from "./userReducer";
import bouser from "./bouserReducer";
import userTrusted from "./userTrustedReducer";
import role from "./roleReducer";
import auth from "./authReducer";
import alert from "./alertReducer";
import domain from "./domainReducer";
import domainPermissions from "./domainPermissionReducer";
import domainRoles from "./domainRoleReducer";
import domainRoleGroups from "./domainRoleGroupReducer";
import setting from "./settingReducer";
import tool from "./toolReducer";
import review from "./reviewReducer";
import report from "./reportReducer";
import video from "./videoReducer";
import log from "./logReducer";
import auditLog from "./auditLogReducer";
import order from "./orderReducer";
import videoFeatured from "./videoFeaturedReducer";
import videoStats from "./videoStatisticsReducer";
import subscription from "./subscriptionReducer";
import productStates from "./productReducer";
import videoSubsStates from "./videoSubsReducer";

const rootReducer = combineReducers({
  waiter: waiterReducer,
  sidebar,
  layout,
  theme,
  toastr,
  user,
  bouser,
  userTrusted,
  role,
  auth,
  alert,
  form: formReducer,
  domain,
  domainPermissions,
  domainRoles,
  domainRoleGroups,
  setting,
  tool,
  review,
  video,
  report,
  log,
  auditLog,
  order,
  videoFeatured,
  videoStats,
  subscription,
  productStates,
  videoSubsStates,
});

export default rootReducer;
