import React from "react";
import moment from "moment";
import { UncontrolledTooltip, Badge } from "reactstrap";
import * as Icon from "react-feather";
import { v4 as uuidv4 } from "uuid";

import { REVENUE_TRANSFER_STATE_LABELS, REVENUE_TRANSFER_STATE_COLORS, READY_FOR_TRANSFER_STATUS_LABELS, READY_FOR_TRANSFER_STATUS_COLORS } from "./constants";

export const rowActionTypes = {
  TRANSFER_REVENUE: "ROW_ACTION_TRANSFER_REVENUE",
  RECALCULATE_MONTHLY_REVENUES: "ROW_ACTION_RECALCULATE_MONTHLY_REVENUES",
  TRANSFER_MONTHLY_REVENUES: "ROW_ACTION_TRANSFER_MONTHLY_REVENUES",
  REQUEST_TO_SETUP: "ROW_ACTION_REQUEST_TO_SETUP"
};

export const getTableColumns = (handleSort = () => { }, isBoAdmin, state, setState, isTransferrable, currentYearMonth, handleRequestToSetup) => {

  return [
    {
      dataField: "userFullname",
      text: "User",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-inline-block fullname align-self-center">
          <p className="mb-0">{row.userFullname}</p>
          <p className="mb-0">{row.userEmailAddress}</p>
          <p className="mb-0 font-italic" style={{ fontSize: '0.75rem' }}>{row.userId}</p>
        </div>
      ),
      headerStyle: () => {
        return { minWidth: '15%' };
      },      
    },
    {
      dataField: "yearMonth",
      text: "Month/ Year",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <div className="order-item">
            <div>{Math.floor(row.yearMonth % 100)}/{Math.floor(row.yearMonth / 100)}</div>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: '120px' };
      },
    },
    {
      dataField: "totalIncome",
      text: "Total Income",
      sort: true,
      formatter: (cell, row) => (
        <div>
          <div>{row.totalIncome ? '$' + row.totalIncome : ''}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
      style: ()=>{
        return {textAlign: 'Right', marginRight: '8px'}
      }
    },
    {
      dataField: "totalCredits",
      text: "Total Credits",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.totalCredits}</div>
      ),
      headerStyle: () => {
        return { width: '100px', textAlign: 'Center' };
      },
      style: ()=>{
        return {textAlign: 'Right', marginRight: '8px', fontWeight: 'bold'}
      }
    },
    {
      dataField: "totalVideoCommission",
      text: "Total Video Commission",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.totalVideoCommission ? '$' + row.totalVideoCommission : ''}</div>
      ),
      headerStyle: () => {
        return { width: '120px' };
      },
      style: ()=>{
        return {textAlign: 'Right', marginRight: '8px'}
      }
    },
    {
      dataField: "totalTextbookCommission",
      text: "Total Textbook Commission",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.totalTextbookCommission ? '$' + row.totalTextbookCommission : ''}</div>
      ),
      headerStyle: () => {
        return { width: '120px' };
      },
      style: ()=>{
        return {textAlign: 'Right', marginRight: '8px'}
      }
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>${row.totalAmount}</div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
      style: ()=>{
        return {textAlign: 'Right', marginRight: '8px', fontWeight: 'bold'}
      }
    },    
    /*
    {
      dataField: "lastModifiedDate",
      text: "Last Modified Date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {moment(row.lastModifiedDate).format('MM/DD/YYYY')}
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    */
    {
      dataField: "readyForTransfer",
      text: "Account Ready?",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.readyForTransfer || row.transferred ? (
            <Badge
              color={READY_FOR_TRANSFER_STATUS_COLORS[row.readyForTransfer || row.transferred]}
              className="mr-1 mb-1"
            >
              {READY_FOR_TRANSFER_STATUS_LABELS[row.readyForTransfer || row.transferred]}
            </Badge>
          ) : (<span>
            {row.userEmailAddress ? (
              <a href="#" className="mr-2" onClick={e => handleRequestToSetup(row.userId, row.userFullname, row.userEmailAddress, e)}>
                Request to setup
              </a>
            ) : (
              <span></span>
            )}
          </span>)}
        </div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
    },
    {
      dataField: "transferred",
      text: "Paid out?",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={REVENUE_TRANSFER_STATE_COLORS[row.transferred]}
            className="mr-1 mb-1"
          >
            {REVENUE_TRANSFER_STATE_LABELS[row.transferred]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "transferDate",
      text: "Transfer Date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.transferDate ? moment(row.transferDate).format('MM/DD/YYYY') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "transferDoc",
      text: "Transfer Document",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.transferDoc}</div>
      ),
      headerStyle: () => {
        return { width: "10%" };
      },
    },

    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col text-center">

          {isBoAdmin && isTransferrable(row, currentYearMonth) ? (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleTransferRevenue(row, e)} id={'transfer-' + row.action_col}>
              <Icon.CornerUpRight className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'transfer-' + row.action_col}>
                Transfer Revenue
              </UncontrolledTooltip>
            </a>
          ) : (<span></span>)}
        </div>
      ),
      formatExtraData: {
        handleTransferRevenue: (row, e) => {
          let message = `<p class="font-weight-bold">Transfer revenue (amount "$${`${row.totalAmount}`}") to the user's Stripe connected account. </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            action: rowActionTypes.TRANSFER_REVENUE,
            message: message,
          });
        },
      },
      headerStyle: () => {
        return { textAlign: "center", width: "80px" };
      },
    },
  ];
}