import axios from "axios";
import config from "../config/config";

const queryString = require("query-string");

function responseMiddleware() {
  /**  */
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response?.config?.url.indexOf(config.endpoints.AUTH_PATH) ===
        -1 &&
        error.response?.status === 401
      ) {
        localStorage.removeItem("user");
        window.location = "/auth/sign-in";
      } else {
        return Promise.reject(error);
      }
    }
  );
}

responseMiddleware();

export async function post(path, data, blobResponse) {
  try {
    const options = {
      method: "POST",
      url: config.endpoints.URL + path,
      timeout: 5*60*1000, // 5 minutes
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    if (data) options.data = data;
    if(blobResponse)
      options.responseType = 'blob';

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token)
      options.headers.Authorization = `Bearer ${user.access_token}`;

    const res = await axios(options);
    if (res && res.status === 200) {
      const data = await res.data;
      return { data };
    }
    return { data: [] };
  } catch (error) {
    return { error: error.response };
  }
}

export async function postFormdata(path, data) {
  try {
    const options = {
      method: "POST",
      url: config.endpoints.URL + path,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data"
      },
    };

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token)
      options.headers.Authorization = `Bearer ${user.access_token}`;

    const res = await axios(options);
    if (res && res.status === 200) {
      const data = await res.data;
      return { data };
    }
    return { data: [] };
  } catch (error) {
    return { error: error.response };
  }
}

export async function get(path, params) {
  try {
    const qs = params ? queryString.stringify(params) : "";
    const options = {
      method: "GET",
      url: config.endpoints.URL + path + (qs ? `?${qs}` : ""),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token)
      options.headers.Authorization = `Bearer ${user.access_token}`;

    const res = await axios(options);
    if (res && res.status === 200) {
      const data = await res.data;
      return { data };
    }
    return { data: [] };
  } catch (error) {
    return { error: error.response };
  }
}


export async function requestDelete(path, params) {
  try {
    const qs = params ? queryString.stringify(params) : "";
    const options = {
      method: "DELETE",
      url: config.endpoints.URL + path + (qs ? `?${qs}` : ""),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token)
      options.headers.Authorization = `Bearer ${user.access_token}`;

    const res = await axios(options);
    if (res && res.status === 200) {
      const data = await res.data;
      return { data };
    }
    return { data: [] };
  } catch (error) {
    return { error: error.response };
  }
}



export async function request(path, data, method = "GET") {
  try {
    const methods = ["get", "post", "put", "delete", "patch"];
    if (methods.indexOf(method.toLowerCase()) === -1) method = "GET";

    const options = {
      method,
      url: config.endpoints.URL + path,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    if (data) options.data = data;

    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token)
      options.headers.Authorization = `Bearer ${user.access_token}`;

    const res = await axios(options);
    if (res && res.status === 200) {
      const data = await res.data;
      return { data };
    }
    return { data: [] };
  } catch (error) {
    return { error: error.response };
  }
}




