import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";

import DebounceInput from "../../components/DebounceInput";
import config from "../../config/config";
import Selectbox from '../../components/Selectbox/Selectbox';

const FormFilter = ({ onSubmitFilter, loading, onToggleShow, paging }) => {

  let videoSearchText = useRef(null);
  let authorSearchText = useRef(null);

  const [cancelDebounce, setCancelDebounce] = useState(false);

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: 'views',
    order: 'DESC',
    type: undefined,
    videoText: '',
    author: '',
    reload: false
  });

  const videoTypeOptions = [
    { label: 'Lecture courses', value: 'LECTURE' },
    { label: 'Seeds videos', value: 'SEEDS' }
  ];


  const handleVideoTypeChange = (option) => {
    if (option && option.value)
      setFilters({ ...filters, type: option.value });
    else
      setFilters({ ...filters, type: undefined });
  }

  const handleInputChange = (value, name) => {
    setCancelDebounce(false);
    setFilters({ ...filters, [name]: value });
  }

  const handleInputSubmit = (value, name) => {
    setFilters({ ...filters, [name]: value, reload: true });
  }

  const submitFilter = () => {
    setCancelDebounce(true);
    if (onSubmitFilter) onSubmitFilter(filters);
  };

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Row className={classNames('video-stats-filter')} style={{ transition: 'visibility 0.5s, opacity 0.5s linear' }}>
        <Col lg="12" xs="12" className="d-flex justify-content-end">
          <div className="filter-input filter-video-id d-flex ml-3 align-items-center">
            <div className="d-flex align-items-center">
              <label htmlFor="videoTypes" className="mb-1">Video types</label>
              <div className="input-wrap d-flex ml-2" style={{ width: '220px' }}>
                <Selectbox
                  name="videoTypes"
                  placeholder="Video type"
                  className="w-100"
                  options={videoTypeOptions}
                  onChange={handleVideoTypeChange}
                />
              </div>
            </div>
          </div>

          <div className="filter-input filter-video-id d-flex ml-3 align-items-center">
            <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">Title/Subject/Short Description</label>
            <div className="input-wrap d-flex" style={{ width: '320px' }}>
              <DebounceInput
                name="videoText"
                placeholder="e.g Algebra"
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleInputChange}
                onSubmit={handleInputSubmit}
                innerRef={videoSearchText}
                required
              // pattern="/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/"
              />
            </div>
          </div>
          <div className="filter-input d-flex ml-3">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">Author</label>
              <div className="input-wrap d-flex" style={{ width: '220px' }}>
                <DebounceInput
                  name="author"
                  className={'w-100 d-flex'}
                  placeholder="e.g Jay Abramson"
                  debounceTime={3000}
                  cancelDebounce={cancelDebounce}
                  onChange={handleInputChange}
                  onSubmit={handleInputSubmit}
                  innerRef={authorSearchText}
                />
              </div>
            </div>
            <Button
              className="ml-3 d-flex"
              size=""
              disabled={loading}
              onClick={(e) => { setCancelDebounce(true); submitFilter(e); }}
            >
              <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '3px', marginRight: '2px' }} /> Search
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);