import React from "react";
import moment from "moment";
import { UncontrolledTooltip, Badge } from "reactstrap";
import * as Icon from "react-feather";
import { v4 as uuidv4 } from "uuid";

import { ORDER_STATUS_COPY, ORDER_STATUS_COLOR } from "./constants";

const prodTypes = {
  'VIDEO': 'Video',
  'RESOURCE': 'Resource',
  'LIVECLASS': 'Live class',
  'SUBSCRIPTION': 'Video subscription',
  'CREDITSBUNDLE': "Credits bundle",
};

const getType = (type) => {
  if (!type || !prodTypes[type])
      return '';  
  return '(' + prodTypes[type] + ')';
}

export const getTableColumns = (handleSort = () => { }, handleReclone) => {
  return [
    {
      dataField: "orderId",
      text: "Order",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <div>#{row.orderId}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: "ownerFullname",
      text: "Buyer",
      sort: true,
      formatter: (cell, row) => (
        <div>
          <p className="mb-0">{row.ownerFullname}</p>
          <p className="mb-0">{row.ownerEmailAddress}</p>
          <p className="mb-0 font-italic" style={{ fontSize: '0.65rem' }}>{row.ownerId}</p>
        </div>
      ),
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "billingAddresss",
      text: "Billing address",
      sort: false,
      formatter: (_, row) => (
        <div>
          <p className="mb-0">{row.billingAddress?.address}</p>
          <p className="mb-0">{row.billingAddress?.city}{', '}{row.billingAddress?.state}{', '}{row.billingAddress?.zip}</p>
          <p className="mb-0">{row.billingAddress?.country}</p>
        </div>
      ),
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "productId",
      text: "Item(s)",
      sort: true,
      formatter: (_, row) => (
        <div>
          {row.items.map(item => (
            <div className="order-item" key={uuidv4()}>
              <div id={item.productId}>{item.productTitle}</div>
              <div><i>{getType(item.productType)}</i></div>
            </div>
          ))}
        </div>
      ),
      headerStyle: () => {
        return {};
      },
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      formatter: (cell, row) => (
        <div>
          ${row.total}
        </div>
      ),
      headerStyle: () => {
        return { width: 100 };
      },
    },
    {
      dataField: "paymentGateway",
      text: "Payment",
      sort: true,
      formatter: (_, row) => (
        <div>
          <div>{row.paymentGateway}</div>
          <div>{row.paymentMethod}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: 120 };
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={ORDER_STATUS_COLOR[row.status]}
            className="mr-1 mb-1"
          >
            {ORDER_STATUS_COPY[row.status]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: 90 };
      },
    },
    {
      dataField: "datePurchased",
      text: "Purchased date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.purchasedAt ? moment(row.purchasedAt).format('HH:mm:ss') : ''}<br />
          {row.purchasedAt ? moment(row.purchasedAt).format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: 140 };
      },
    }
  ];
}