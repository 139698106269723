import { all } from "redux-saga/effects";
import userSaga from "./userSaga";
import bouserSaga from "./bouserSaga";
import roleSaga from "./roleSaga";
import domainSaga from "./domainSaga";
import domainPermissionSaga from "./domainPermissionSaga";
import domainRoleSaga from "./domainRoleSaga";
import domainRoleGroupSaga from "./domainRoleGroupSaga";
import userTrustedSaga from "./userTrustedSaga";
import settingSaga from "./settingSaga";
import toolSaga from "./toolSaga";
import reviewSaga from "./reviewSaga";
import videoSaga from "./videoSaga";
import reportSaga from "./reportSaga";
import logSaga from "./logSaga";
import auditLogSaga from "./auditLogSaga";
import orderSaga from "./orderSaga";
import videoFeaturedSaga from "./videoFeaturedSaga";
import videoStatisticsSaga from "./videoStatisticsSaga";
import subscriptionSaga from "./subscriptionSaga";
import productSaga from "./productSaga";
import videoSubsSaga from "./videoSubsSaga";
import creditBundlesSaga from "./creditBundlesSaga";

export default function* rootSaga() {
  yield all([
    userSaga(),
    userTrustedSaga(),
    bouserSaga(),
    roleSaga(),
    domainSaga(),
    domainPermissionSaga(),
    domainRoleSaga(),
    domainRoleGroupSaga(),
    settingSaga(),
    toolSaga(),
    reviewSaga(),
    videoSaga(),
    reportSaga(),
    logSaga(),
    auditLogSaga(),
    orderSaga(),
    videoFeaturedSaga(),
    videoStatisticsSaga(),
    subscriptionSaga(),
    productSaga(),
    videoSubsSaga(),
    creditBundlesSaga(),
  ]);
}
