import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";

import VideoDelete from "./VideoFeaturedDelete";
import FormFilter from "./FormFilter";
import VideoFeaturedAdd from "./VideoFeaturedAdd";
import VideoFeaturedUpdate from "./VideoFeaturedUpdate";
import VideoFeaturedStatus from "./VideoFeaturedStatus";

import { featuredVideoAction, resyncAction } from "../../redux/reducers/videoFeaturedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const getDatetimeStr = (date) => {
  if (!date) return '';
  return `${date.getFullYear()}-${_.padStart(date.getMonth() + 1, 2, 0)}-${_.padStart(date.getDate(), 2, 0)}T${_.padStart(date.getHours(), 2, 0)}:${_.padStart(date.getMinutes(), 2, 0)}:00.000+00:00`;
}

const VideoList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalAdd: false,
    modalUpdate: false,
    modalDelete: false,
    modalToggleStatus: false,
    action: '',
    message: '',
    row: null,
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: 'createdDate',
    sortReal: '',
    order: 'desc',
    enabled: '',
    ownerId: '',
    ownerKeyword: '',
    videoId: '',
    videoKeyword: '',
    validStartDate: '',
    validThruDate: '',
    reload: false,
  });

  const [waitingSearch, setWaitingSearch] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [nodata, setNodata] = useState(false);

  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);
  const videos = useSelector(state => state.videoFeatured?.list) || [];
  const fetching = useSelector(state => state.videoFeatured?.fetching);
  const totalItems = useSelector(state => state.videoFeatured.total);
  const totalPages = useSelector(state => state.videoFeatured.totalPages);
  const pageNumber = useSelector(state => state.videoFeatured.pageNumber);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;
  const showDatatable = (nodata || (!waitingSearch && videos.length === 0)) ? false : waitingSearch ? false : true;

  const dataList = videos.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const tableColumns = [
    {
      dataField: "title",
      text: "Video",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div><span className="font-weight-bold" title={row?.title}>{_.truncate(row?.title, { 'length': 80, })}</span></div>
          <div><span className="font-weight-bold" title={row?.subject}>{_.truncate(row?.subject, { 'length': 80, })}</span></div>
          <div><span className="font-weight-bold" title={row?.topic}>{_.truncate(row?.topic, { 'length': 80, })}</span></div>
          <div><span title={row?.uid} style={{ fontStyle: 'italic' }}>{row?.uid}</span></div>
        </div>
      ),
      headerStyle: () => {
        // return { width: "35%" };
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div>{row?.type}</div>          
        </div>
      ),
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "ownerId",
      text: "Author",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div title={row?.ownerName}>
          <div>{row?.ownerName}</div>
          <div>{row?.ownerEmail}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "rankingLevel",
      text: "Ranking level",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div title={row?.rankingLevel}>
          <div className="text-right pr-1">{row?.rankingLevel}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "validStartDate",
      text: "Valid start date",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          {row.validStartDate ? moment(row.validStartDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY/MM/DD HH:mm') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "160px" };
      },
    },
    {
      dataField: "validThruDate",
      text: "Valid through date",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          {row.validThruDate ? moment(row.validThruDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY/MM/DD HH:mm') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "160px" };
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div className="">
            <Badge
              color={row.enabled ? 'success' : 'gray'}
              className="mr-1 mb-1"
            >
              {row.enabled ? 'Enabled' : 'Disabled'}
            </Badge>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "85px" };
      },
    },

    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col">
          <a href="#" className="mr-2" onClick={e => formatExtraData.handleUpdate(row, e)} id={'edit-' + row.action_col}>
            <Icon.Edit className="text-dark" style={{ width: "15px" }} />
            <UncontrolledTooltip target={'edit-' + row.action_col}>
              Edit
            </UncontrolledTooltip>
          </a>
          {row.enabled ? (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleToggleStatus(row, e)} id={'disable-' + row.action_col}>
              <Icon.Slash className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'disable-' + row.action_col}>
                Disable
              </UncontrolledTooltip>
            </a>
          ) : (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleToggleStatus(row, e)} id={'enable-' + row.action_col}>
              <Icon.CheckCircle className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'enable-' + row.action_col}>
                Enable
              </UncontrolledTooltip>
            </a>
          )}
          {isBoAdmin && (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleDelete(row, e)} id={'delete-' + row.action_col}>
              <Icon.Trash2 className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'delete-' + row.action_col}>
                Remove from featured list
              </UncontrolledTooltip>
            </a>
          )}
        </div>
      ),
      formatExtraData: {
        handleToggleStatus: (row, e) => {
          setState({ ...state, modalToggleStatus: true, row });
        },
        handleDelete: (row, e) => {
          setState({ ...state, modalDelete: true, row });
        },
        handleUpdate: (row, e) => {
          setState({ ...state, modalUpdate: true, row });
        },
      },
      headerStyle: () => {
        return { width: "130px" };
      },
    },
  ];

  const onColumnSort = (field, order) => {
    let realSort = '';
    switch (field) {
      default:
        realSort = field;
    }
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onActionButtonClick = (action, row) => {
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        setWaiting(true);
        if (params.enabled === '') delete params.enabled;
        if (!params.ownerId) delete params.ownerId;
        if (!params.ownerKeyword) delete params.ownerKeyword;
        if (!params.videoId) delete params.videoId;
        if (!params.videoKeyword) delete params.videoKeyword;
        if (!params.validStartDate) delete params.validStartDate;
        if (!params.validThruDate) delete params.validThruDate;

        await startActionWithPromise(
          featuredVideoAction.fetch,
          { params: params, successCallback: () => { }, failedCallback: () => { } },
          dispatch
        );
      } catch { }
      setWaiting(false);
      setWaitingSearch(false);
    })();
  };

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  }

  const handleSyncFeaturedVideos = () => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          resyncAction,
          { successCallback: () => { }, failedCallback: () => { } },
          dispatch);

      } catch { }
      finally {
        setWaiting(false);
        refreshDatatable();
      }
    })()
  }

  const handleAddFeaturedVideo = () => {
    setState({ ...state, modalAdd: true });
  }

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        // enabled: filters.enabled,
        ownerId: filters.ownerId,
        ownerKeyword: filters.ownerKeyword,
        videoId: filters.videoId,
        videoKeyword: filters.videoId ? '' : filters.videoKeyword,
        validStartDate: filters.validStartDate ? getDatetimeStr(filters.validStartDate) : '',
        validThruDate: filters.validThruDate ? getDatetimeStr(filters.validThruDate) : '',
        sort: {
          dir: `${filters.order.toUpperCase()}`,
          fieldName: `${filters.sort}`
        }
      };

      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
      sort: {
        dir: `${filters.order.toUpperCase()}`,
        fieldName: `${filters.sort}`
      }
    };

    fetchData(params);
  }, []);

  return (
    <>
      <Card className={classNames('w-100 mb-0 video-review-list')}>
        <CardHeader className="">
          <FormFilter
            onSubmitFilter={handleSubmitFilter}
            onResyncFeaturedVideos={handleSyncFeaturedVideos}
            loading={fetching}
            handleAdd={handleAddFeaturedVideo}
            bulkAction={true}
          />
        </CardHeader>
        <CardBody className="pt-0 data-list">
          {(nodata || (!waitingSearch && videos.length === 0)) ? (
            <div className="loading-content text-center p-4 border-top">
              <p>No video found.</p>
            </div>
          ) : waiting ? (
            <div className="loading-content text-center p-4 border-top">
              <Spinner size="sm" />
            </div>
          ) : (
            <>
              <DataTable
                keyField="action_col"
                data={dataList}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onActionClick={onActionButtonClick}
                hideSelectColumn={true}
              />
              <Row className="mt-3">
                <Col lg="3" md="4" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={(size) => setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true })}
                  />

                </Col>
                <Col lg="9" md="8" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={(p) => setFilters({ ...filters, pageNumber: p, reload: true })}
                  />
                </Col>
              </Row>
            </>
          )}
          {/* )} */}
        </CardBody>
      </Card>
      {state.modalAdd && (
        <VideoFeaturedAdd
          modal={state.modalAdd}
          toggle={toggleModal}
          refreshData={refreshDatatable}
        />
      )}
      <VideoDelete
        modal={state.modalDelete}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      {state.modalToggleStatus && (
        <VideoFeaturedStatus
          modal={state.modalToggleStatus}
          toggle={toggleModal}
          row={state.row}
          refreshData={refreshDatatable}
        />
      )}
      {state.modalUpdate && (
        <VideoFeaturedUpdate
          modal={state.modalUpdate}
          toggle={toggleModal}
          row={state.row}
          refreshData={refreshDatatable}
        />
      )}

    </>
  );
};

export default VideoList;
