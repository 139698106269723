import { takeLatest, call, put } from "redux-saga/effects";

import creditBundlesServices from "../../services/creditBundlesService";
import { alertTypes } from "../constants";
import {
    searchCreditBundles,
    saveCreditBundles,
    deleteCreditBundles,
    getCreditRate,
} from "../reducers/creditBundlesReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";


function* searchCreditBundlesWorker(action) {
    const { data, error } = yield call(
        creditBundlesServices.search,
        action.payload?.params
    );
    if (error) {
        if (error.headers["x-message-info"]) {
            yield put({
                type: alertTypes.ERROR,
                message: error.headers["x-message-info"],
            });
            throw new Error(error.headers["x-message-info"]);
        }
    } else {
        return data;
    }
    return false;
}

function* saveCreditBundlesWorker(action) {
    const { data, error } = yield call(
        creditBundlesServices.save,
        action.payload?.params
    );
    if (error) {
        if (error.headers["x-message-info"]) {
            yield put({
                type: alertTypes.ERROR,
                message: error.headers["x-message-info"],
            });
            throw new Error(error.headers["x-message-info"]);
        }
    } else {
        return data;
    }
    return false;
}

function* deleteCreditBundlesWorker(action) {
    const { data, error } = yield call(
        creditBundlesServices.markDelete,
        action.payload?.params
    );
    if (error) {
        if (error.headers["x-message-info"]) {
            yield put({
                type: alertTypes.ERROR,
                message: error.headers["x-message-info"],
            });
            throw new Error(error.headers["x-message-info"]);
        }
    } else {
        return data;
    }
    return false;
}

function* getCreditRateWorker(action) {
    const { data, error } = yield call(
        creditBundlesServices.creditRate
    );
    if (error) {
        if (error.headers["x-message-info"]) {
            yield put({
                type: alertTypes.ERROR,
                message: error.headers["x-message-info"],
            });
            throw new Error(error.headers["x-message-info"]);
        }
    } else {
        return data;
    }
    return false;
}

function* creditBundlesSaga() {
    yield takeLatest(
        searchCreditBundles.start,
        searchCreditBundles.waiterActionForSaga(sagaPromise(searchCreditBundlesWorker))
    );
    yield takeLatest(
        saveCreditBundles.start,
        saveCreditBundles.waiterActionForSaga(sagaPromise(saveCreditBundlesWorker))
    );
    yield takeLatest(
        deleteCreditBundles.start,
        deleteCreditBundles.waiterActionForSaga(sagaPromise(deleteCreditBundlesWorker))
    );
    yield takeLatest(
        getCreditRate.start,
        getCreditRate.waiterActionForSaga(sagaPromise(getCreditRateWorker))
    );
}

export default creditBundlesSaga;